@use '/src/assets/scss/indexV2.scss';
@import '/src/assets/scss/variablesV2';

$border-radius: 4px;
.main {
  // background: #f00 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px solid var( --green-alpha-alpha-16);
  border-radius: $border-radius;

  .left_block {
    // background: #0f0 !important;
    display: flex;
    // width: 306px;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-top-left-radius: $border-radius -1;
    border-bottom-left-radius: $border-radius -1;

    // background: var(--green-alpha-alpha-16);
    // background: url(map-get($imgs,'Gradient/BG 1 Default')) no-repeat;
    background: linear-gradient(180deg, rgba(100, 255, 218, 0.16) 0%, rgba(100, 255, 218, 0) 100%);
    background-size: 100% auto; 
    width: 282px; //=figma 11 Protein Properties > 02-panel

    .step_text {
      align-self: stretch;
      color: var(--green-alpha-alpha-75);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .title {
      @extend .Title-Audiowide20px-Regular;
    }
    
    .subtitle {
      padding-top: 24px;
      @extend .Contents-QuanticoCaption12px-Medium-White_75;
    }
  }

  .right_block {
    // background: #f00;
    width: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 100;
    align-self: stretch;
    // border-top-right-radius: $border-radius;
    // border-bottom-right-radius: $border-radius;

    .main_block{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.main_purple {
  // background: #f00 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--logo-colors-logo-purple-16);
  border-radius: $border-radius;

  .left_block {
    // background: #0f0 !important;
    display: flex;
    // width: 306px;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-top-left-radius: $border-radius -1;
    border-bottom-left-radius: $border-radius -1;

    background: linear-gradient(180deg, rgba(74, 58, 120, 0.5) 0%, rgba(151, 137, 208, 0) 100%);
    background-size: 100% auto; 
    width: 282px; //=figma 11 Protein Properties > 02-panel

    .step_text {
      align-self: stretch;
      color:rgb(150, 136, 206);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .title {
      @extend .Title-Audiowide20px-Regular;
    }
    
    .subtitle {
      padding-top: 24px;
      @extend .Contents-QuanticoCaption12px-Medium-White_75;
    }
  }

  .right_block {
    // background: #f00;
    width: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 100;
    align-self: stretch;
    // border-top-right-radius: $border-radius;
    // border-bottom-right-radius: $border-radius;

    .main_block{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}