// @use 'colors' as c;
// @use 'fonts' as f;
@use '/src/assets/scss/colors';
@use '/src/assets/scss/fonts';
@use 'variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/common.scss';
@import '/src/assets/scss/commonV2.scss';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #C0C7C8; //=rgba(255, 255, 255, 0.75) + #030909
  // background: #09181D; //=rgba(255, 255, 255, 0.20) + #030909
  // background: #07141A; //=rgba(255, 255, 255, 0.16) + #030909
  background: #101e23; //=rgba(255, 255, 255, 0.10) + #030909
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

* {
  font-family: Quantico ;
}

a {
  color:var(--green-alpha-alpha-75);
  // text-decoration: underline;
  text-decoration: none;
}

.link {
  color: var(--green-alpha-alpha-75);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.App2 {
  // background-color: #0f0 !important;
  // background: rgba(3, 9, 9, 1); //NOT work
  background: rgba(3, 9, 9, 0.99);

  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 1440px;
  min-width: 1440px;

  margin-left: calc((100vw - 1440px) / 2);
}