// @import '/src/assets/scss/colors.css';
// @import '/src/assets/scss/fonts.css';
@import '/src/assets/scss/commonV2.scss';


.button.button {
  box-sizing: border-box !important;
 position: relative !important;
 display: inline-flex !important;
 align-items: center !important;
 justify-content: center !important;
 width: max-content !important; 
 height: 45px !important;
 min-height: 45px !important; 
 padding: 8px 16px !important;

  @extend .Menu-item-Borders;
  @extend .Contents-QuanticoBody16px-Regular-GreenNormal-Btn;
    
  &:hover {
    width: max-content !important;
    height: 45px !important;
    min-height: 45px !important;

    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    box-shadow: 40px 80px 120px 0px rgba(100, 255, 218, 0.3);
    box-shadow: 0px 4px 32px 0px rgba(100, 255, 218, 0.6);
    @extend .Menu-item-Borders;
    @extend .Contents-QuanticoBody16px-Regular-White-Btn;

  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Menu-item-Borders;
    }
  }
}


.secondary16_button.secondary16_button {
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 45px !important;
  padding: 8px 16px 8px 16px !important;
  color: var(--white-white-50) !important;
  @extend .Contents-QuanticoBody16px-Regular-Btn;

  &:hover {
    color: var(--white-white) !important;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Contents-QuanticoBody16px-Regular-Btn;
  }

  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
  }
}

.secondary12_button {
  // width: Hug (134px)px;
  // height: Hug (38px)px;
  // padding: 8px 16px 8px 16px;
  // gap: 8px;
  border: 1px 0px 0px 0px;
  
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  
  height: 38px !important;
  padding: 8px 16px 8px 16px !important;
  
  &:global(.MuiButtonBase-root) {
    // White/White_50
    color: var(--white-white-50) !important;
    //styleName: Contents: Quantico/Caption/12px: Regular;
    @extend .Contents-QuanticoCaption12px-Regular;
  }

  &:hover {
    color: var(--white-white) !important;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Contents-QuanticoCaption12px-SemiBold;
  }
  
  &:active {
    //styleName: Contents: Quantico/Caption/12px: SemiBold;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
  }
}