@import '/src/assets/scss/commonV2.scss';

// Total
.layout {
  // background-color: #f0f !important;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  flex-wrap: wrap;
  
  // width: Fixed (840px)px;
  // height: Hug (190px)px;
  // top: 516px;
  // left: 300px;
  gap: 10px;
  
  
  width: 840px;
  height: 190px;
}

//------------------------------------------------------------------------------
// Card/Homepage figures
.card {
  // background: #f0f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // width: Fixed (150px)px;
  // height: Fixed (90px)px;
  padding: 0px 0px 6px 0px;
  gap: 0px;
  
  
  width: 150px;
  height: 90px;
  
  // Gradient/BG Green 2
  @extend .Background-Gradient-BGGreen2;
  // Gradient/Stroke 1
  @extend .Border-Gradient-Stroke1;
  
  .card_number {
    white-space: pre-line;
    height: 50%;
    @extend .Title-Audiowide28px-Regular-Title;
  }

  .card_text {
    height: 40%;
    text-align: center;
    line-height: 1.2;
    @extend .Contents-QuanticoAssistive-Text10px-SemiBold-Alpha_75;
  }
}
