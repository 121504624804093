@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';
@import '/src/assets/scss/fonts.css';

.timelineCard {
  // background: #f00 !important;
  width: 170px;
  height: 100px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 4px 4px 4px 4px;
  background: rgba(3, 9, 9, 0.8);
  opacity: 1;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // color: white;
}


  .timelineCardLarge {
    @extend .timelineCard;
    width: 200px;
    height: 108px;
  }


// PURPLE THEME
.purple {
  box-shadow: inset 0px 2px 2px 0px rgba(151, 137, 208, 1);

  .cardHeader {
    border-bottom: 4px solid rgba(151, 137, 208, 1);
    @extend .Title-Audiowide20px-Regular-purple;
  }

  &:hover {
    background: rgba(121, 107, 175, 1);
    
    .cardHeader {
      color: rgba(255, 255, 255, 1);
      border-bottom: 4px solid rgba(255, 255, 255, 1);
    }
    
    .cardContent {
      color: rgba(255, 255, 255, 1);
    }
  }
}

// GREEN THEME
.green {
  box-shadow: inset 0px 2px 2px 0px rgba(89, 191, 188, 1);

  .cardHeader {
    border-bottom: 4px solid rgba(89, 191, 188, 1);
    @extend .Title-Audiowide20px-Regular-purple; 
    color: rgba(89, 191, 188, 1);
  }

  &:hover {
    background: rgba(89, 191, 188, 1);
    
    .cardHeader {
      color: rgba(255, 255, 255, 1);
      border-bottom: 4px solid rgba(255, 255, 255, 1);
    }
    
    .cardContent {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.cardHeader {
  // background: #ff0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  // font-size: 13px;
}

.cardContent {
  // background: #0ff;
  font-size: 13px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
}

.link {
  @include underline;
  color: rgba(255, 255, 255, 0.75);
}