// @use '/src/assets/scss/variables' as v;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

// level and Information Bar
.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // box-sizing: border-box;
  
  // width: Fill (1,440px)px;
  // height: Hug (70px)px;
  // padding: 8px 20px 40px 20px;
  
  padding: 8px 120px 8px 120px;
  //8px+12px=20px to bottom
}

// level and Information Bar
.frame {
  // background: #0ff;
  gap: 8px;
  
  // min-height: 70px;
  min-width: 1200px;
}

// Description
//styleName: Contents: Quantico/Body/16px: Medium;

.top_desc {
  // background: #f00;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  // padding: 0px 120px;
  gap: 16px;

  width: 100%;
  // height: 43px;

}