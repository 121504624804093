// @use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;
@use '/src/assets/scss/indexV2.scss';



//TODO::cue DELETE
// .chart_block_title {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   @include font.heading4;
// }

.chart_type_btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // gap: 24px;
  padding-right: 40px;

  button {
    color: var(--green-alpha-alpha-40);
    &.selected_chart_type {
      color: var(--white-white) !important;
    }
  }
}


.formula_block {
  // background: #f0f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

  .symbol {
    font-size: 45px;
    font-weight: 800;
    padding: 0 16px;
  }

  .multifunctional_peptides_counts,
  .total_peptide_sequences {
    width: 230px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    background-color: var(--green-alpha-alpha-20);

    &:hover {
      cursor: pointer;
      background-color: var(--green-alpha-alpha-30);
      font-weight: 600;
    }
  }

  .ratio {
    width: 100px;
    height: 80px;
    background-color: var(--green-alpha-alpha-60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.header_cell{
  background-color:var(--green-alpha-alpha-20);
}

.btns_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  padding-top: 20px;
  padding-bottom: 10px;

  // button {
  //   @include btn-type;
  // }

  .white_btns_block {
    display: flex;
    gap: 5px;
  }

  // .general_btn {
  //   width: 130px;
  // }

  // .search_btn {
  //   margin-left: 40px;
  //   background-color: v.$green2 !important;
  //   color: white !important;
  // }
}

.cls_text_link {
  word-break: 'break-word';
  &:hover {
    cursor: pointer;
  }
}

.sticky_header {
  position: sticky;
  top: 0;
  // color: blue;
  z-index: 1;
}