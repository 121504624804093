// @use '/src/assets/scss/variables' as v;
// @import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

// level
.frame {
  // background: #00f;

  display: flex;
  align-items: flex-start;
  // box-sizing: border-box;
  
  // width: Hug (98px)px;
  // height: Hug (22px)px;
  gap: 8px;
  height: 22px;
}

//---------------------------------------------------------------------------
.link {
  @include underline;
  // @include hover-underline;
}
