@import '/src/assets/scss/commonV2.scss';

.main {
  // background: #f0f !important;
  box-sizing: border-box;
  display: flex;
  // justify-content: start;
  // align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  
  // height: 350px;
  // width: 350px;
  
  // Cards/Protein
  .card {
    // background: #ff0;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
        
    padding: 12px;
    gap: 12px;
  }
  
  // Pictures
  .card_img {
    // background: #f00 !important;
    // background: var(--neutral-grey-25-flood, #f4f4f4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    
    // width: 295.38px;
    // height: 212.52px;
    // top: 7.1px;
    // left: 19.5px;
    // gap: 0px;
    opacity: 0.9px;
    
    height: 168px;
    height: 200px;
  }
  
  // Contents
  .card_content {
    // background: #0ff;
    display: flex;
    flex-direction: column;
    // width: Fixed (290.13px)px;
    // height: Fixed (90.75px)px;
    // top: 229.63px;
    // left: 24.75px;
    // padding: 5.5px 16.5px 5.5px 16.5px;
    // gap: 5.5px;
    
    word-break: keep-all;
    
    padding: 0px;
    width: 298px;
  }

  .title {
    // Gradient/Title
    @extend .Title-Audiowide16px-Regular-Title;
  }

  .content {
    //styleName: Contents: Quantico/Caption/12px: Medium;
    // White/White_50
    // @extend .Contents-QuanticoCaption12px-Medium-White_50;
    @extend .Contents-QuanticoCaption12px-Medium-White_90;
    font-size: 14px;
  }
}
