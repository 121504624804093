

.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  
  gap: 24px;
}

//------------------------------------------------------------------------------

.manual_rows {
  // background-color: #088;
  
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  width: 100%;
}

.input_box {
  // background: #808;
  // margin: 0;
  // flex: 1;
  width: 100%;
}

.button_bar {
  // background: #00f;
  display: flex;
  flex-direction: row;
  
  gap: 10px;
}

.button_icon {
  color: var(--green-normal);
}
//------------------------------------------------------------------------------

.frame_submit {
  // background: #0ff;
  
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
