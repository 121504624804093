@use '/src/assets/scss/variablesV2' as v2;
@import '/src/assets/scss/commonV2.scss';

.layout {
  // background-color: #ff0;
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  // width: Fill (1,400px)px;
  // height: Hug (684px)px;
  // gap: 16px;
  
  gap: 24px;
  // padding-left: 40px;
}

.frame_grid {
  // background-color: #0ff;
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  
  width: 330px + 354px + 354px;
}

.tools_container {
  // background-color: #f0f;
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 每行3個
  gap: 24px;
  padding: 24px;
}

.tool_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
  text-align: center;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 8px;
    color: var(--white);
  }

  p {
    color: var(--white-50);
    font-size: 14px;
  }
}