
.chart_type_btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding: 12px 40px 12px 0;

  button {
      &.selected_chart_type {
        color: aliceblue;
      }
    }
  }