@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

//common
.table_container {
  // background: #f00 !important;
  display: flex;
  justify-content: center;

  border-radius: 8px;
  // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
  // background: #FFF;
  border: 1px solid v.$grey25;
  // background: v.$white;

  .loading_circle {
    padding: 5px 0;
    color: v.$green2;
  }
}

.table_header {
  height: 64px;
  box-sizing: border-box;

  &.MuiTableCell-root {
    vertical-align: middle;
  }
}

.frame_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.frame_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

//---------------------------------------------------------------------------
// color
.bgc-green {
  background-color: v.$green;
}

.bgc-grey50 {
  background-color: v.$grey50;
}

.bgc-grey75 {
  background-color: v.$grey75;
}

.bgc-grey100 {
  background-color: v.$grey100;
}

.bgc-dark-green {
  background-color: v.$darkGreen;
}

.bgc-white {
  background-color: v.$white;
}

//---------------------------------------------------------------------------
.color-white {
  color: v.$white;
  
  &.MuiTableCell-body, &.MuiTableCell-head {
    color: v.$white;
  }
}

.color-grey100 {
  color: v.$grey100;
}

.color-white {
  color: v.$white;
}

.color-red {
  color: v.$red;
  
  &.-body, &.MuiTableCell-head {
    coloMuiTableCellr: v.$red;
  }
}

//---------------------------------------------------------------------------
//font
.h1-darkGreen {
  @include heading1-darkGreen;
}

.h2-darkGreen {
  @include heading2-darkGreen;
}

.h3-darkGreen {
  @include heading3-darkGreen;
}

.h4-darkGreen {
  @include heading4-darkGreen;
}

.h4-green75 {
  @include heading4-green75;
}

//---------------------------------------------------------------------------
.b1-grey50 {
  @include body1-grey50;
}

.b1-grey100 {
  @include body1-grey100;
}

.b1-darkGreen {
  @include body1-darkGreen;
}

.b1-green4 {
  @include body1-green4;
}

.b1-red {
  @include body1-red;
}

.b1strong-darkGreen {
  @include body1strong-darkGreen;
}

.b1strong-green4 {
  @include body1strong-green4;

}

//---------------------------------------------------------------------------
.b2-amwayBlack {
  @include body2-amwayBlack;
}

.b2-darkGreen {
  @include body2-darkGreen;
}

.b2-grey100 {
  @include body2-grey100;
}

.b2-white {
  @include body2-white;
}

.b2strong {
  @include body2strong;
}

.b2strong-white {
  @include body2strong-white;
}

.b2strong-darkGreen {
  @include body2strong-darkGreen;
}

//---------------------------------------------------------------------------
.b3-grey100 {
  @include body3-grey100;
}

//---------------------------------------------------------------------------

.size18-grey100 {
  @include size18-grey100;
}

.size20strong {
  @include size20strong;
}

.size20strong-white {
  @include size20strong-white;
}

.size20strong-darkGreen {
  @include size20strong-darkGreen;
}

.size20strong-grey100 {
  @include size20strong-grey100;
}

//---------------------------------------------------------------------------
.MuiSlider-root {
  color: v.$darkGreen !important;
}

//---------------------------------------------------------------------------

.hover-text-underline {
  &:hover {
    text-decoration: underline;
  }
}

.hover-cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
