@use '/src/assets/scss/variablesV2' as v2;
@use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';

.main {
  // background: #f00;
  
  // width: 100%;
  display: flex;
  box-sizing: border-box;
}

.nc_table_body_cell {
  font-size: 10px !important;
}

//Q	N	G	S	T...
.table_body_cell {
  font-size: 12px;
  
  // padding: 2px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
}

// Peptide
.a_table_body_cell_1st,
.table_body_cell_1st {
  color: v2.$white !important;
  // background: v2.$white50 !important;
}

// N-terminal (%), C-terminal (%)
.table_body_cell_1st {
  font-size: 12px !important;
}

// Amino acid
.a_table_body_cell_1st {
  &:global(.MuiTableCell-body) {
    max-width: 100px;
  }
}
