@use '/src/assets/scss/variables' as v;
// @use '/src/assets/scss/variablesV2' as v2;
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';



.layout_card {
  // background-color: #f00;
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  
  // width: Fill (1,400px)px;
  // height: Hug (684px)px;
  // gap: 16px;
  
  gap: 12px;
  
  .card {
    // background: #0ff;
    width: 230px;
  }
}

@mixin btn-type {
  text-transform: none;
  color: #fff !important;
  border: solid 0.5px v.$green2 !important;
}

.chart_type_btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-bottom: 12px;

  button {
    position: relative !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 45px !important;
    padding: 8px 16px 8px 16px !important;
    @extend .Menu-item-Borders;
    @extend .Contents-QuanticoBody16px-Regular-GreenNormal;

    &:hover {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      box-shadow: 40px 80px 120px 0px rgba(100, 255, 218, 0.3);
      box-shadow: 0px 4px 32px 0px rgba(100, 255, 218, 0.6);
      @extend .Menu-item-Borders;
      @extend .Contents-QuanticoBody16px-SemiBold-White;
    }

    &:active {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      @extend .Menu-item-Borders;
    }

    &.selected_chart_type {
      color: #fff;
      box-shadow: 0px 0px 8px 2px rgba(75, 191, 164, 0.6);
    }
  }
}


.layout_seq {
  // background-color: #00f;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  
  // width: Fill (1,400px)px;
  // height: Hug (684px)px;
  // gap: 16px;
  gap: 24px;
  
  .seq_chart {
    // background-color: #0ff;
    width: 49%;
  }
}
