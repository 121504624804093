@import '/src/assets/scss/commonV2.scss';
@import '/src/assets/scss/pseudo';

.main {
  // background: #f0f !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // align-content: flex-start;
  justify-content: space-between;
  
  @extend .Border-Gradient-Stroke1;

  :global {
    .MuiTableBody-root {
      tr {
        height: 48px; // 調整行高
        
        td {
          padding: 8px 10px; // 調整單元格內邊距
        }
      }
    }
  }

}

.card {
  // background: #ff0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  
  // padding: 12px;
  // gap: 12px;
}

.title {
  // background: #0ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 46px;
}

// Cow milk
.text_title_link,
.text_title {
  @extend .Title-Audiowide16px-Regular-Title;
}
// Milk Plant Animal Marine Microorganism
.text_title_link {
  @include underline;
}

.title_image {
  // background: #f00 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
  // width: 200px;
  // height: 168px;
}

.table_key {
  // background: #00f;
  width: 160px;
}
.text_key {
  // @extend .Contents-QuanticoBody16px-Medium-White_75;
  @extend .Contents-QuanticoCaption12px-Medium-White_75;
}

.table_value {
  // background: #0f0;
  // width: 100px;
}
.text_value {
  @extend .Contents-QuanticoCaption12px-Medium-White_75;
  // @extend .Contents-QuanticoBody16px-Medium-White_75;
}

.text_value_link {
  cursor: pointer;
  @extend .Contents-QuanticoCaption12px-Medium-Green;
  // @extend .Contents-QuanticoBody16px-Medium-Green;
  
  &:hover {
    @extend .Contents-QuanticoCaption12px-Medium-White;
    // @extend .Contents-QuanticoBody16px-Medium-White;
  }
}