@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/commonV2.scss';

// Tag
.top_result {
  // background: #f00;
  display: flex;
  align-items: end;
  // align-self: stretch;
  gap: 16px;
  padding: 10px 0;

  height: 50px;

  // Tag //todo
  .result_buttons {
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-top: 0;


    .button {
      display: flex;
      // height: 40px;
      padding: 8px 12px 6px 12px;
      align-items: center;
      margin-right: 10px;
      // font-size: 16px;
      // border-radius: 8px;
    }
  }

  // Chip
  .result_texts {
    // background: #0f0;
    margin: 5px;
    display: flex;
    align-items: center;
    align-self: stretch;

    padding: 2px 8px 2px 8px;
    gap: 4px;
    border-radius: 2px 0px 0px 0px;

    // Green Alpha/Alpha_8
    background: var(--green-alpha-alpha-8);
  }
}
