@import '/src/assets/scss/commonV2.scss';
@import "/src/components/button/Buttons.module.scss";
// C:\Project-SPP\spp-fe-web\src\components\button\Buttons.module.scss

.main {
  // background: #f00 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  
  // width: Fill (1,440px)px;
  // height: Hug (556px)px;
  padding: 120px 0px 120px 0px;
  gap: 32px;
  
  
  // Gradient/BG Green 3
  background: url( map-get($imgs, 'Gradient/BG Green 3') );
  background-repeat: no-repeat;
	background-position: center;
  background-size: 100% 100%;
}

//------------------------------------------------------------------------------
// Login
.title {
  // background: #0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  
  //styleName: Title: Audiowide/38px: Regular;
  // Gradient/Title
  @extend .Title-Audiowide38px-Regular-Title;
}

// Text Fields
.frame_input {
  // background: #0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  // width: Hug (400px)px;
  // height: Hug (108px)px;
  gap: 8px;
  
  
  width: 400px;
}

// Text Fields
.input {
  // background: #0ff !important;
  
  // width: Fixed (400px)px;
  // height: Fixed (50px)px;
  // padding: 0px 16px 0px 16px;
  // gap: 16px;
  // border-radius: 4px 0px 0px 0px;
  // border: 1px 0px 0px 0px;
  // 
  
  // Green Alpha/Alpha_16
  // border: 1px solid rgba(100, 255, 218, 0.16);
  border: 1px solid var(--green-alpha-alpha-16);
  
  &:hover {
    // Green Alpha/Alpha_8
    // background: rgba(100, 255, 218, 0.08);
    background: var(--green-alpha-alpha-8);
    // Green Alpha/Alpha_16
    // border: 1px solid rgba(100, 255, 218, 0.16);
    border: 1px solid var(--green-alpha-alpha-16);
    // White/White_75
    color: var(--white-white-75);
  }
  
  &:active {
    // Green Alpha/Alpha_8
    // background: rgba(100, 255, 218, 0.08);
    background: var(--green-alpha-alpha-8);
    // Green Alpha/Alpha_16
    // border: 1px solid rgba(100, 255, 218, 0.16);
    border: 1px solid var(--green-alpha-alpha-16);
  }
  
  &:global(.MuiInputBase-root) {
    // White/White
    color:  var(--white-white);
    //styleName: Contents: Quantico/Body/16px: Regular;
    @extend .Contents-QuanticoBody16px-Regular;
  }
  
  &:global(.MuiOutlinedInput-input.Mui-disabled) {
    // background: #f0f;
    color:  var(--white-white-50);
    @extend .Contents-QuanticoBody16px-Regular;
  }
}

//------------------------------------------------------------------------------
// Frame 11
.frame_button {
  // background: #0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  // width: Hug (1,440px)px;
  // height: Hug (91px)px;
  gap: 8px;
  
}

.login_btn {
  // background: #0ff !important;
  // width: Fixed (400px)px;
  // height: Hug (45px)px;
  // padding: 8px 16px 8px 16px;
  // gap: 8px;
  // border: 1px 0px 0px 0px;
  // 

  width: 400px;
  height: 45px;
  
  &:global(.MuiButtonBase-root) {
    background: transparent;
    @extend .Contents-QuanticoBody16px-Regular-GreenNormal;
    @extend .Menu-item-Borders;
    
    &:disabled {
      border: 0px;
      // White/White_50
      color: var(--white-white-50);
    }
    
    &:hover {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      box-shadow: 40px 80px 120px 0px rgba(100, 255, 218, 0.3);
      box-shadow: 0px 4px 32px 0px rgba(100, 255, 218, 0.6);
      @extend .Menu-item-Borders;
      @extend .Contents-QuanticoBody16px-SemiBold-White;
    }
    
    &:active {
      background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
      @extend .Menu-item-Borders;
    }
  }
}

// Frame 11 lv2
.button_text {
  // background: #00f;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  // width: Fixed (1,440px)px;
  // height: Fixed (38px)px;
  gap: 8px;
  
}