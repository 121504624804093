@import '/src/assets/scss/commonV2.scss';

// Cards/Tools
.gradient_card,
.gradient_card_disable {
  // background: #0f0 !important;
  box-sizing: border-box;
  width: 330px;
  height: 330px;
  // position: relative;
}

.gradient_card {
  transition: all 0.3s ease;
  filter: brightness(0.85); /* 暗 */
  
  // Gradient/BG 1 Default
  background: linear-gradient(180deg, rgba(100, 255, 218, 0.08) 0%, rgba(100, 255, 218, 0) 100%);
  
  // Gradient/Stroke 2
  @extend .Border-Gradient-Stroke1;

  &:hover {
    filter: brightness(1); /* 亮 */
    
    box-shadow: 
      40px 80px 120px 0px rgba(100, 255, 218, 0.3),
      0px 4px 32px 0px rgba(100, 255, 218, 0.6);
      
    border: 4px solid;
    // Gradient/Stroke 2
    @include GradientStroke2;

    // .border_path,
    // .border_path_overlay {
    //   stroke: rgba(100, 255, 218, 0.4);
    //   stroke-width: 4px;
    // }
  }

  // &:active {
  //   .border_path {
  //     stroke: url(#paint1_linear_4006_2821);
  //     stroke-width: 4px;
  //   }
    
  //   .border_path_overlay {
  //     stroke: #64FFDA;
  //     stroke-opacity: 0.16;
  //     stroke-width: 4px;
  //   }
  // }
}

// .card_background {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
// }

// .border_path,
// .border_path_overlay {
//   transition: all 0.3s ease;
// }

.card_content {
  // background: #f0f;
  box-sizing: border-box;
  display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
// .card_content {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   z-index: 1;
// }

