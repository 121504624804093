/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2024 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --white-white: #fff;
  --white-white-98: rgba(255, 255, 255, 0.98);
  --white-white-96: rgba(255, 255, 255, 0.96);
  --white-white-94: rgba(255, 255, 255, 0.94);
  --white-white-92: rgba(255, 255, 255, 0.92);
  --white-white-90: rgba(255, 255, 255, 0.9);
  --white-white-80: rgba(255, 255, 255, 0.8);
  --white-white-75: rgba(255, 255, 255, 0.75);
  --white-white-60: rgba(255, 255, 255, 0.6);
  --white-white-50: rgba(255, 255, 255, 0.5);
  --white-white-40: rgba(255, 255, 255, 0.4);
  --white-white-30: rgba(255, 255, 255, 0.3);
  --white-white-24: rgba(255, 255, 255, 0.24);
  --white-white-20: rgba(255, 255, 255, 0.2);
  --white-white-16: rgba(255, 255, 255, 0.16);
  --white-white-12: rgba(255, 255, 255, 0.12);
  --white-white-10: rgba(255, 255, 255, 0.1);
  --white-white-8: rgba(255, 255, 255, 0.08);
  --white-white-4: rgba(255, 255, 255, 0.04);
  --black-black: #030909;
  --black-black-98: rgba(3, 9, 9, 0.98);
  --black-black-96: rgba(3, 9, 9, 0.96);
  --black-black-94: rgba(3, 9, 9, 0.94);
  --black-black-92: rgba(3, 9, 9, 0.92);
  --black-black-90: rgba(3, 9, 9, 0.9);
  --black-black-80: rgba(3, 9, 9, 0.8);
  --black-black-75: rgba(3, 9, 9, 0.75);
  --black-black-60: rgba(3, 9, 9, 0.6);
  --black-black-50: rgba(3, 9, 9, 0.5);
  --black-black-40: rgba(3, 9, 9, 0.4);
  --black-black-30: rgba(3, 9, 9, 0.3);
  --black-black-24: rgba(3, 9, 9, 0.24);
  --black-black-20: rgba(3, 9, 9, 0.2);
  --black-black-16: rgba(3, 9, 9, 0.16);
  --black-black-12: rgba(3, 9, 9, 0.12);
  --black-black-10: rgba(3, 9, 9, 0.1);
  --black-black-8: rgba(3, 9, 9, 0.08);
  --black-black-4: rgba(3, 9, 9, 0.04);
  --gradient-stroke-2: rgba(100, 255, 218, 0.16);
  --gradient-bg-green-1: #030909;
  --gradient-bg-green-2: rgba(3, 9, 9, 0.6);
  --gradient-bg-green-3: rgba(3, 9, 9, 0.6);
  --green-light: #f0fffb;
  --green-light-hover: #e8fff9;
  --green-light-active: #cffff4;
  --green-normal: #64ffda;
  --green-normal-hover: #5ae6c4;
  --green-normal-active: #50ccae;
  --green-dark: #4bbfa4;
  --green-dark-hover: #3c9983;
  --green-dark-active: #2d7362;
  --green-darker: #23594c;
  --green-alpha-alpha-98: rgba(100, 255, 218, 0.98);
  --green-alpha-alpha-96: rgba(100, 255, 218, 0.96);
  --green-alpha-alpha-94: rgba(100, 255, 218, 0.94);
  --green-alpha-alpha-92: rgba(100, 255, 218, 0.92);
  --green-alpha-alpha-90: rgba(100, 255, 218, 0.9);
  --green-alpha-alpha-80: rgba(100, 255, 218, 0.8);
  --green-alpha-alpha-75: rgba(100, 255, 218, 0.75);
  --green-alpha-alpha-60: rgba(100, 255, 218, 0.6);
  --green-alpha-alpha-50: rgba(100, 255, 218, 0.5);
  --green-alpha-alpha-40: rgba(100, 255, 218, 0.4);
  --green-alpha-alpha-30: rgba(100, 255, 218, 0.3);
  --green-alpha-alpha-24: rgba(100, 255, 218, 0.24);
  --green-alpha-alpha-20: rgba(100, 255, 218, 0.2);
  --green-alpha-alpha-16: rgba(100, 255, 218, 0.16);
  --green-alpha-alpha-12: rgba(100, 255, 218, 0.12);
  --green-alpha-alpha-10: rgba(100, 255, 218, 0.1);
  --green-alpha-alpha-8: rgba(100, 255, 218, 0.08);
  --green-alpha-alpha-4: rgba(100, 255, 218, 0.04);
  --chart-colors-red: #f31260;
  --chart-colors-yellow: #f5a524;
  --chart-colors-green: #17c964;
  --chart-colors-violet: #7828c8;
  --chart-colors-blue: #006fee;
  --logo-colors-logo-purple: #796baf;
  --logo-colors-logo-purple-16: rgba(121, 107, 175, 0.4);
  --logo-colors-logo-purple-8: rgba(121, 107, 175, 0.24);

}