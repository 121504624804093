// @use '/src/assets/scss/variables' as v;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

// Main Frame
.layout {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // box-sizing: border-box;
  
  // width: Fill (1,440px)px;
  // height: Hug (1,000px)px;
  padding: 8px 0px 8px 0px;
  gap: 0px;
  
  // width: 1240px;
}

//------------------------------------------------------------------------------
// level and Information Bar
// move to BreadBarV2.jsx
/* 
.frame_top {
}
 */
 
//---------------------------------------------------------------------------
// 2 columns / Contents
.frame_bottom {
  // background-color: #f00 !important;
  
  // width: Fill (1,440px)px;
  // height: Hug (817px)px;
  // padding: 8px 20px 40px 20px;
  gap: 24px;
  
  padding: 12px 120px 40px 120px;
}