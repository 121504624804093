
.top_block {
  // background: #f00;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search_block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search_text_area {
  min-width: 1120px;
  max-width: 1120px;
  min-height: 100px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: var(--green-alpha-alpha-16) 0.5px solid;
}

.btn_block {
  display: flex;

  .btn {
    text-transform: none !important;
    color: var(--green-alpha-alpha-16) !important;
    border: solid var(--green-alpha-alpha-16) 0.5px !important;
    margin-right: 16px;
  }
}

tbody {
  // border-bottom: 2px double var(--green-alpha-alpha-16);
}