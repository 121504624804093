// @use 'colors' as c;
// @use 'fonts' as f;
@use '/src/assets/scss/colors';
@use '/src/assets/scss/fonts';
@import 'variablesV2';

// color
//------------------------------------------------------------------------------
// Gradient/Title
.GradientTitle {
  background: linear-gradient(180deg, #FFFFFF 0%, #64FFDA 100%);
  background-clip: text;
  -webkit-background-clip: text; /* 限制背景到文字區域 */
  -webkit-text-fill-color: transparent; /* 使文字本身透明 */
}

// Linear Gradient
.LinearGradient {
  background: linear-gradient(180deg, #FFFFFF 0%, #64FFDA 64%, #7828C8 100%);
  background-clip: text;
  -webkit-background-clip: text; /* 限制背景到文字區域 */
  -webkit-text-fill-color: transparent; /* 使文字本身透明 */
}

// font + color
//---------------------------------------------------------------------------
// Title
.Title-Audiowide38px-Regular-Title {
  @extend .Title-Audiowide38px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
}

.Title-Audiowide48px-Regular-LinearGradient {
  @extend .Title-Audiowide48px-Regular;
  // Gradient/Title
  @extend .LinearGradient;
}

//styleName: Title: Audiowide/28px: Regular;
.Title-Audiowide28px-Regular-Title {
  @extend .Title-Audiowide28px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
}

//styleName: Title: Audiowide/28px: Regular;
.Title-Audiowide28px-Regular-Title-White {
  @extend .Title-Audiowide28px-Regular;
  color: var(--white-white);
}

//styleName: Title: Audiowide/24px: Regular;
.Title-Audiowide24px-Regular-Title {
  @extend .Title-Audiowide24px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
}

//styleName: Title: Audiowide/20px: Regular;
.Title-Audiowide20px-Regular {
  @extend .Title-Audiowide20px-Regular ;
  // Gradient/Title
  color: var(--white-white);
}

.Title-Audiowide20px-Regular-purple {
  @extend .Title-Audiowide12px-Regular ;
  // Gradient/Title
  color: rgba(151, 137, 208, 1);
  ;
}

.Title-Audiowide20px-Regular-White_75 {
  @extend .Title-Audiowide20px-Regular ;
  // Gradient/Title
  color: var(--white-white-75);
}
.Title-Audiowide20px-Regular-White_50 {
  @extend .Title-Audiowide20px-Regular ;
  // Gradient/Title
  color: var(--white-white-50);
}

.Title-Audiowide20px-Regular-White_16 {
  @extend .Title-Audiowide20px-Regular ;
  // Gradient/Title
  color: var(--white-white-16);
}

//styleName: Title: Audiowide/16px: Regular;
.Title-Audiowide16px-Regular-White_75 {
  @extend .Title-Audiowide16px-Regular;
  // White/White_75
  color: var(--white-white-75);
}
.Title-Audiowide16px-Regular-Title {
  @extend .Title-Audiowide16px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
}
.Title-Audiowide16px-Regular {
  @extend .Title-Audiowide16px-Regular;
  // White/White_75
  color: var(--white-white);
}
.Title-Audiowide20px-Regular-Title {
  @extend .Title-Audiowide20px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
}

.Title-Audiowide12px-Regular-Title {
  @extend .Title-Audiowide12px-Regular;
  // Gradient/Title
  @extend .GradientTitle;
  font-size: 15px;
}

//styleName: Title: Audiowide/12px: Regular;
.Title-Audiowide12px-Regular-White_75 {
  @extend .Title-Audiowide12px-Regular;
  // White/White_75
  color: var(--white-white-75);
}

//------------------------------------------------------------------------------
//styleName: Contents: Quantico/Body/px: Bold;
.Contents-QuanticoBody20px-Bold-White_50 {
  @extend .Contents-QuanticoBody20px-Bold;
  color: var(--white-white-50);
}

//styleName: Contents: Quantico/Body/16px: Regular;
.Contents-QuanticoBody16px-Regular-White_50 {
  @extend .Contents-QuanticoBody16px-Regular;
  // White/White_50
  color: var(--white-white-50);
}

.Contents-QuanticoBody16px-Regular-White_50-Btn {
  @extend .Contents-QuanticoBody16px-Regular-White_50;

  &:hover {
    // color: #0f0 !important;
    color: var(--white-white);
  }
  &:active {
    // color: #00f !important;
    color: var(--white-white);
  }
}

.Contents-QuanticoBody16px-Regular-GreenNormal {
  @extend .Contents-QuanticoBody16px-Regular;
  // Green/Normal
  color:rgba(100, 255, 218, 1);
}

.Contents-QuanticoBody16px-Regular-GreenNormal-Btn {
  @extend .Contents-QuanticoBody16px-Regular-Btn;
  // Green/Normal
  color:rgba(100, 255, 218, 1);
}

.Contents-QuanticoBody16px-Regular-White-Btn {
  @extend .Contents-QuanticoBody16px-Regular-Btn;
  // Green/Normal
  color:var(--white-white);
}

.Contents-QuanticoBody16px-Regular-Black {
  @extend .Contents-QuanticoBody16px-Regular;
  color:rgba(3, 9, 9, 1);
  &:hover {
    @extend .Contents-QuanticoBody16px-SemiBold;
    color:rgba(3, 9, 9, 1);
  }
}
.Contents-QuanticoBody16px-Regular-White_75 {
  @extend .Contents-QuanticoBody16px-Regular;
  // White/White_50
  color: var(--white-white-75);
}

.Contents-QuanticoBody16px-Regular-White_80-Sidebar-title {
  @extend .Contents-QuanticoBody16px-Regular-White_80;

  &:hover {
    color: var(--white-white);
    text-decoration: underline; 
  }
}

.Contents-QuanticoBody16px-Regular-White_80-Sidebar {
  @extend .Contents-QuanticoBody16px-Regular-White_80;

  color: var(--white-80);

  &:hover {
    color: var(--white-white); 
    text-decoration: underline; 
    // @extend .Contents-QuanticoBody16px-SemiBold; 
  }

  &.active {
    color: red;
  }
}

//Quantico/Body/16px: Medium
.Contents-QuanticoBody16px-Medium-Green {
  @extend .Contents-QuanticoBody16px-Medium;
  // Green/Normal
  color: var(--green-normal)
}
.Contents-QuanticoBody16px-Medium-White_90 {
  @extend .Contents-QuanticoBody16px-Medium;
  // White/White_90
  color: var(--white-white-90);
}
.Contents-QuanticoBody16px-Medium-White_75 {
  @extend .Contents-QuanticoBody16px-Medium;
  // White/White_75
  color: var(--white-white-75);
}
.Contents-QuanticoBody16px-Medium-White_50 {
  @extend .Contents-QuanticoBody16px-Medium;
  color:rgba(255, 255, 255, 0.5);
}

.Contents-QuanticoBody16px-Medium-White {
  @extend .Contents-QuanticoBody16px-Medium;
  color: var(--white-white);
}
.Contents-QuanticoBody16px-Medium-Red {
  @extend .Contents-QuanticoBody16px-Medium;
  color: #F53D7A;
}
// Quantico/Body/16px: SemiBold
.Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 {
  @extend .Contents-QuanticoBody16px-SemiBold;
  color:rgba(100, 255, 218, 0.75);
}

.Contents-QuanticoBody16px-SemiBold-Purple {
  @extend .Contents-QuanticoBody16px-SemiBold;
  color:rgb(150, 136, 206);
}

.Contents-QuanticoBody16px-SemiBold-White {
  @extend .Contents-QuanticoBody16px-SemiBold;
  color: rgba(255, 255, 255, 1);
}

.Contents-QuanticoBody16px-SemiBold-White-Btn {
  @extend .Contents-QuanticoBody16px-SemiBold-Btn;
  color: rgba(255, 255, 255, 1);
}
//------------------------------------------------------------------------------
//styleName: Contents: Quantico/Caption/12px: Medium;
.Contents-QuanticoCaption12px-Medium-Green {
  @extend .Contents-QuanticoCaption12px-Medium;
  color: var(--green-normal);
}
.Contents-QuanticoCaption12px-Medium-GreenDarker {
  @extend .Contents-QuanticoCaption12px-Medium;
  // Green/Darker
  color: var(--green-darker)
}
.Contents-QuanticoCaption12px-Medium-Alpha_80 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // Green Alpha/Alpha_80
  color: var(--green-alpha-alpha-80);
}
.Contents-QuanticoCaption12px-Medium-Alpha_75 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // Green Alpha/Alpha_75
  color: var(--green-alpha-alpha-75);
}

.Contents-QuanticoCaption18px-Medium-Alpha_75 {
  @extend .Contents-QuanticoCaption18px-Medium;
  // Green Alpha/Alpha_75
  color: var(--green-alpha-alpha-75);
}

.Contents-QuanticoCaption12px-Medium-Alpha_50 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // White/White_50
  color: var(--green-alpha-alpha-50);
}

.Contents-QuanticoCaption12px-Medium-White {
  @extend .Contents-QuanticoCaption12px-Medium;
  // White/White_75
  color: var(--white-white);
  font-size: 14px;
}

.Contents-QuanticoCaption12px-Medium-White_75 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // White/White_75
  color: var(--white-white-75);
}

.Contents-QuanticoCaption12px-Medium-White_50 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // White/White_50
  color: var(--white-white-50);
}

.Contents-QuanticoCaption12px-Medium-White_90 {
  @extend .Contents-QuanticoCaption12px-Medium;
  // White/White_50
  color: var(--white-white-90);
  font-size: 14px;
}

.Contents-QuanticoCaption12px-Medium-Black {
  @extend .Contents-QuanticoCaption12px-Medium;
  // Green/Darker
  color: var(--black-black)
}
//------------------------------------------------------------------------------
//styleName: Contents: Quantico/Caption/12px: Regular;
.Contents-QuanticoCaption12px-Regular-White_50 {
  @extend .Contents-QuanticoCaption12px-Regular;
  // White/White_50
  color: rgba(255, 255, 255, 0.5);
}
.Contents-QuanticoCaption12px-Regular-White_50-Btn {
  @extend .Contents-QuanticoCaption12px-Regular-White_50;
  
  &:hover {
    // color: #0f0 !important;
    color: rgba(255, 255, 255, 1);
  }
  &:active {
    // color: #00f !important;
    color: rgba(255, 255, 255, 1);
  }
}

.Contents-QuanticoCaption12px-Regular-White_90 {
  @extend .Contents-QuanticoCaption12px-Regular;
  // White/White_90
  color: var(--white-white-90);
}
.Contents-QuanticoCaption12px-Regular-White_90-Link {
  @extend .Contents-QuanticoCaption12px-Regular-White_90;
  &:hover {
    // color: #0f0 !important;
    color: var(--white-white)
  }
  &:active {
    // color: #00f !important;
    color: var(--white-white)
  }
}

.Contents-QuanticoCaption12px-Regular-White_75 {
  @extend .Contents-QuanticoCaption12px-Regular;
  // White/White_75
  color: var(--white-white-75);
}

.Contents-QuanticoCaption12px-Regular-Alpha_60 {
  @extend .Contents-QuanticoCaption12px-Regular;
  // Green Alpha/Alpha_60
  color: var(--green-alpha-alpha-60);
}

.Contents-QuanticoCaption12px-Regular-GreenAlpha_60 {
  @extend .Contents-QuanticoCaption12px-Regular;
  color:rgba(100, 255, 218, 0.6);
}

.Contents-QuanticoCaption12px-Regular-Alpha_75 {
  @extend .Contents-QuanticoCaption12px-Regular;
  // Green Alpha/Alpha_75
  color: var(--green-alpha-alpha-75);
}

.Contents-QuanticoCaption12px-Regular-Black {
  @extend .Contents-QuanticoCaption12px-Regular;
  color: var(--black-black)
}

.Contents-QuanticoCaption12px-Regular-BgGreen2 {
  @extend .Contents-QuanticoCaption12px-Regular;
  color: var(--gradient-bg-green-2)
}

//------------------------------------------------------------------------------
// Contents
//styleName: Contents: Quantico/Assistive Text/10px: SemiBold;
.Contents-QuanticoAssistive-Text10px-SemiBold-Alpha_75 {
  @extend .Contents-QuanticoAssistive-Text10px-SemiBold;
  // Green Alpha/Alpha_75
  color: var(--green-alpha-alpha-75)
}

.Contents-QuanticoBody16px-Regular-White_80 {
  @extend .Contents-QuanticoBody16px-Regular;
  // White/White_80
  color: var(--white-white-80);
}