@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';


//figma 12 Protein Properties
// 02-card
.layout {
  // background-color: #00f;
  
  width: 1200;
  height: 117;
  // box-sizing: border-box;
  
  display: flex;
  // padding: 0px 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  
  top: 8px;

  border-radius: 4px;
  @extend .Borders-Gradient-Stroke1-radius;
}

// Frame 29679
.frame {
  display: flex;
  padding: 24px 40px;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  
  background: linear-gradient(195.05deg, rgba(43, 255, 255, 0.1) 0%, rgba(43, 255, 255, 0.02) 50%, rgba(43, 255, 255, 0.06) 100%);
}
//---------------------------------------------------------------------------

// Frame 1000002374
.frame_1 {
  // background: #f00;
  
  width: 256px;
  // height: 69;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// Frame 1000002375
// Frame 1000002376
.frame_2 {
  // background: #0f0;
  
  width: 208px;
  // height: 48;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  word-wrap: break-word;
}

.frame_34 {
  // background: #00f;
  
  width: 208px;
  // height: 48;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.line {
  // background-color: #f00 !important;
  
  width: 1px;
  height: 70px;
  
  // background: #DFDFDF;
  background: var(--green-alpha-alpha-16);
}
//---------------------------------------------------------------------------
