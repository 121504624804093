// Global scss variable, can be imported to any scss file.
/*
for scss import e.g.
@import 'assets/scss/variablesV2';
*/
// @use 'colors' as c;
// @use 'fonts' as f;
@use '/src/assets/scss/colors';
@use '/src/assets/scss/fonts';

// e.g.
// url( $imagePath + 'Stroke 2.svg');
// url( map-get($imgs, 'Gradient/Stroke 2') );
$pathAboutUs: '/assets/img/AboutUs/';
$imagePath: '/assets/img/Styles/';
$imageLightPath: '/assets/img/Styles/Light';
$imgs: (
  // '': $imagePath + '',
  'Gradient/BG 1 Default': $imagePath + 'BG 1 Default.svg',
  'Gradient/BG 2 Hover': $imagePath + 'BG 2 Hover.svg',
  'Gradient/BG 3 Active': $imagePath + 'BG 3 Active.svg',
  'Gradient/BG 4 Hover': $imagePath + 'BG 4 Hover.svg',

  'Gradient/BG Green 1': $imagePath + 'BG Green 1.svg',
  'Gradient/BG Green 2': $imagePath + 'BG Green 2.svg',
  'Gradient/BG Green 3': $imagePath + 'BG Green 3.svg',
  'Gradient/BG Green 4': $imagePath + 'BG Green 4.svg',
  'Gradient/Color Logo': $imagePath + 'Color Logo.svg',
  'Gradient/Line 1': $imagePath + 'Line 1.svg',
  'Gradient/Stroke 1': $imagePath + 'Stroke 1.svg',
  'Gradient/Stroke 2': $imagePath + 'Stroke 2.svg',
  
  // '': $imageLightPath + '',
  'Gradient/Light/BG 1': $imageLightPath + 'BG 1.svg',
  'Gradient/Light/BG 2': $imageLightPath + 'BG 2.svg',
  'Gradient/Light/BG 3': $imageLightPath + 'BG 3.svg',
  'Gradient/Light/BG Green 2': $imageLightPath + 'BG Green 2.svg',
  'Gradient/Light/Line 3': $imageLightPath + 'Line 3.svg',
  'Gradient/Light/Stroke 1': $imageLightPath + 'Stroke 1.svg',
  'Gradient/Light/Stroke 2': $imageLightPath + 'Stroke 2.svg',
  'Gradient/Light/Stroke 3': $imageLightPath + 'Stroke 3.svg',
  'Gradient/Light/Stroke 4': $imageLightPath + 'Stroke 4.svg',
  
);

// Gradient/Line 1
@mixin GradientLine1 {
  // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%);
  border-image-source: url( map-get($imgs, 'Gradient/Line 1') );
  border-image-slice: 1;
}

// Gradient/Stroke 1
@mixin GradientStroke1 {
  // border-image-source: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
  border-image-source: url( map-get($imgs, 'Gradient/Stroke 1') );
  border-image-slice: 1;
}

// Gradient/Stroke 2
@mixin GradientStroke2 {
  // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%),
  // linear-gradient(0deg, rgba(100, 255, 218, 0.16), rgba(100, 255, 218, 0.16));
  
  // border-image-source: url('/assets/img/Styles/Stroke 2.svg'); /* 使用 SVG 作為邊框圖像 */
  // border-image-source: url( $imagePath + 'Stroke 2.svg');
  border-image-source: url( map-get($imgs, 'Gradient/Stroke 2') );
  border-image-slice: 1;
}

/* Color Variable */
/*
Export to scss file. e.g.
@use '/src/assets/scss/variablesV2' as v2;
...
color: v2.$greenNormal,
OR
@import '/src/assets/scss/variablesV2';
...
color: $greenNormal,
 */
$white: var(--white-white);
$white50: rgba(255, 255, 255, 0.5);
$darkLight: #f0fffb;
$green: #64ffda;
$greenNormal: var(--green-normal); //#64ffda;
$greenDark: #23594c;

/*
Export to js file. e.g.
import v2 from 'assets/scss/_variablesV2.scss';
...
color: v2.white,
 */
 
:export {
  white: var(--white-white);
  white98: var(--white-white-98);
  white96: var(--white-white-96);
  white94: var(--white-white-94);
  white92: var(--white-white-92);
  white90: var(--white-white-90);
  white80: var(--white-white-80);
  white75: rgba(255, 255, 255, 0.75);
  white60: var(--white-white-60);
  white50: rgba(255, 255, 255, 0.5);
  white40: var(--white-white-40);
  white30: var(--white-white-30);
  white24: var(--white-white-24);
  white20: var(--white-white-20);
  white16: var(--white-white-16);
  white12: var(--white-white-12);
  white10: var(--white-white-10);
  white8: var(--white-white-8);
  white4: var(--white-white-4);
  
  black: var(--black-black);
  black98: var(--black-black-98);
  black96: var(--black-black-96);
  black94: var(--black-black-94);
  black92: var(--black-black-92);
  black90: var(--black-black-90);
  black80: var(--black-black-80);
  black75: var(--black-black-75);
  black60: var(--black-black-60);
  black50: var(--black-black-50);
  black40: var(--black-black-40);
  black30: var(--black-black-30);
  black24: var(--black-black-24);
  black20: var(--black-black-20);
  black16: var(--black-black-16);
  black12: var(--black-black-12);
  black10: var(--black-black-10);
  black8: var(--black-black-8);
  black4: var(--black-black-4);
  
  stroke2: var(--gradient-stroke-2);
  bgGreen: var(--gradient-bg-green-1);
  bgGreen2: var(--gradient-bg-green-2);
  bgGreen3: var(--gradient-bg-green-3);
  
  greenLight: var(--green-light);
  greenLightHover: var(--green-light-hover);
  greenLightActive: var(--green-light-active);
  
  greenNormal: #64ffda;
  greenNormalHover: #5ae6c4;
  greenNormalActive: #50ccae;
  
  green: #64ffda;
  
  greenDark: var(--green-dark);
  greenDarkHover: var(--green-dark-hover);
  greenDarkActive: var(--green-dark-active);
  greenDarker: var(--green-darker);
  
  green98: var(--green-alpha-alpha-98);
  green96: var(--green-alpha-alpha-96);
  green94: var(--green-alpha-alpha-94);
  green92: var(--green-alpha-alpha-92);
  green90: var(--green-alpha-alpha-90);
  green80: var(--green-alpha-alpha-80);
  green75: var(--green-alpha-alpha-75);
  green60: var(--green-alpha-alpha-60);
  green50: var(--green-alpha-alpha-50);
  green40: var(--green-alpha-alpha-40);
  green30: var(--green-alpha-alpha-30);
  green24: var(--green-alpha-alpha-24);
  green20: var(--green-alpha-alpha-20);
  green16: var(--green-alpha-alpha-16);
  green12: var(--green-alpha-alpha-12);
  green10: var(--green-alpha-alpha-10);
  green8: var(--green-alpha-alpha-8);
  green4: var(--green-alpha-alpha-4);
  
  red: var(--chart-colors-red);
  
  logoPurple: #796baf;
  logoPurpleLink: rgb(150, 136, 206);
  
  imagePath: $imagePath;
  imageLightPath: $imageLightPath;
}