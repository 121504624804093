@use '/src/assets/scss/indexV2' ;

.frame_muation {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-self: stretch;

  width: 1200px;
  height: 56px;
}

.mutation_table {
  padding-left: 8px;
}

.table_body_cell_1st,
.table_body_cell {
  font-size: 16px !important;
  min-width: 16px;
  font-family: 'Quantico' !important;
  color: var(--white-white-75) !important;
  background:var(--green-alpha-alpha-20);
}
