// @use 'colors' as c;
// @use 'fonts' as f;
@use '/src/assets/scss/colors';
@use '/src/assets/scss/fonts';
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/commonV2.scss';



//02 Proteins
.layout {
  // background-color: #00f;
  width: 1440px;
}

$header-padding-bottom: 24px;
$header-height: #{72 + 58 + $header-padding-bottom};

//---------------------------------------------------------------------------
.frame_bottom {
  // background: #f00;

  position: relative;
  display: flex;
  align-items: flex-start;
  
  gap: 16px;

}
// Btn/Sidebar Menu item
  .bottom_sort {
  // background: #7fbcee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  width: 240px;
  min-height: 800px;
  padding: 25px 8px 8px 3px;
  
  }
    .sort_title {
      // background: #0ff !important;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding-left: 6px;
      
    }

    
    .sort_total {
      display: flex;
      align-items: center;
      justify-content: start;
      justify-items: center;
      width: 210px;
      height: 50px;
      box-sizing: border-box; 
      margin-bottom: 5px;
    }

    .sort_mike {
        // background-color: #f00 !important;
        display: flex;
        align-items: center;
        justify-content: start;
        justify-items: center;
        
        width: 210px;
        height: 50px;
        // padding: 30px 0 0 0 ;
        border: 2px solid transparent; 
        cursor: pointer;
        box-sizing: border-box; 
        margin-bottom: 5px;
      
        &:hover {
          background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
          @extend .Menu-item-Borders;
        }
      
        &:active {
          background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
          @extend .Menu-item-Borders;
        }
      }
  
  
  
    .sort2nd
    {
      // background: rgb(76, 76, 57) ;
      /* 01-tab */
      width: 200px;
      box-sizing: border-box;

      display: flex;
      height: 30px;
      padding-left: 20px;
      // padding: 16px 12px;
      align-self: stretch;
      align-items: flex-start;
    }

    .result_texts {
    // background: #0f0;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px; 
    }
    
    .result_item {

      display: flex;
      align-items: center;
      height: 26px;

      padding: 2px 8px 2px 8px;
      border-radius: 2px;
      gap: 2px; 
      background: var(--green-alpha-alpha-8); 
    }

//---------------------------------------------------------------------------
/* 03-section-header */
// .frame_top {
//   // background-color: #0f0 !important;
//   /* 03-section-header */
//   position: absolute;
//   top: 0px;
//   padding-bottom: $header-padding-bottom;

//   /* Auto layout */
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   // padding-bottom: 80px;
//   box-sizing: border-box;

//   width: 1440px;
//   // height: 250px; //=112+58+80

//   /* Neutral/Grey 25 - Flood */
//   background: #F4F4F4;
//   // background: v.$grey25;

//   /* Frame 29680 */
//   .top_desc {
//     // background: #f00;
//     /* Frame 29732 */

//     /* Auto layout */
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     padding: 0px 120px;
//     gap: 16px;

//     // width: 1440px;
//     height: 43px;

//   }
// }

//---------------------------------------------------------------------------

/* Frame 29689 */
.bottom_list {
  // background: #0ff;
  /* Frame 29689 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  // width: 1152px;
  // height: 866px;

  .cards_block {
    // background: #f0f !important;
    min-height: 500px;
    max-height: 820px;
    // width: 1200px - 240px;
    overflow-y: auto;
    padding-right: 24px;
    box-sizing: Border-box;
    
    top: 12px;

    /* Frame 29686 */
    .list_bar {
      // background: #f0f;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 20px;  
      isolation: isolate;
      
      padding-top: 4px;
    
      // width: 1050px;  
    
      .bar_box {
        // background: #f0f;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
    
        width: 200px;
        height: 240px;
        
        // brightness in scss .gradient_card
        
        .box_img {
          // background: #f00;
          width: 200px;
          height: 168px;
          // gap: 0px;
          // opacity: 0.8px;
          
          // width: 200px;
          // height: 160px;
        }

        /* Frame 29681 */
        .box_text {
          // background: #0f0;

          box-sizing: border-box;
          /* Frame 29681 */

          display: flex;
          flex-direction: column;
          justify-content: start;

          width: 200px;
          height: 120px;
          padding: 0px 16px 0px 16px;
        }
      }
    }
  }
}
//---------------------------------------------------------------------------
// .text_sort {
//   // background: #ff0;
//   /* Mike */

//   // width: 37px;
//   // height: 27px;
  
//   // text-decoration: underline;

//   // &:hover {
//   //   text-decoration: underline;
//   //   cursor: pointer;
//   // }
// }

// .text_sort2nd {
//   // background: #ff0;

//   // width: 37px;
//   // height: 27px;
// }

// /* 10 species • 170 results */
// .list_count {
//   /* 10 species • 170 results */

//   // width: 894px;
//   // height: 24px;
// }

// /* Bovine Milk */
// .text_name {
//   // background: #ff0;

//   // width: 91px;
//   // height: 27px;
// }

// /* Results: 43 */
// .text_count {
//   // background: #0ff;
  
//   // width: 74px;
//   // height: 24px;
// }

// $count-height: 24; //10 species h
// $bottom-padding-top: 64; //Frame 29690 padding-top
// $bottom-padding-bottom: 128; //Frame 29690 padding-bottom
// $bar-height: 120; //Frame 29686 h
// $bar-gap: 24; //Frame 29686 gap

// // using for js file
// :export {
//   // selectBg: $select-background;
//   // // selectBg: v.$green; //debug
//   // selectBorderBottom: $select-border-bottom;
//   // selectBorderRadius: $select-border-radius;

//   paddingHeight: #{$count-height + $bottom-padding-top + $bottom-padding-bottom};
//   barHeight: #{$bar-height + $bar-gap};
// }