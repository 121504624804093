@use '/src/assets/scss/variables' as v;

.card {
  filter: brightness(0.85); /* 暗 */
  
  &:hover {
    filter: brightness(1); /* 亮 */
  }
}

.main {
  // background: #f00;
  padding: 16px 16px 16px 16px !important;
}


