/*
@import '/src/assets/scss/commonV2.scss';
*/
@use '/src/assets/scss/colors';
@use '/src/assets/scss/fonts';
@import '/src/assets/scss/variablesV2';
@import '/src/assets/scss/fonts_colorsV2';

//---------------------------------------------------------------------------
// color
.greenNormal {
  color: var(--green-normal);
}
// Gradient/BG Green 2
.Background-Gradient-BGGreen2 {
  background: linear-gradient(0deg, rgba(3, 9, 9, 0.6), rgba(3, 9, 9, 0.6)),
  radial-gradient(41.33% 93.34% at 50.2% 7.2%, rgba(100, 255, 218, 0.2) 0%, rgba(10, 10, 10, 0) 100%);
}
  
// Gradient/Stroke 1
.Border-Gradient-Stroke1 {
  border: 2px solid;
  @include GradientStroke1;
  // @extend .Gradient-Stroke1; //NOT WORK
}

.Borders-Gradient-Stroke1-radius {
  position: relative;
  border-radius: 4px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border: 2px solid transparent;
    background: linear-gradient(180deg, 
      rgba(43, 255, 255, 0.32) 0%, 
      rgba(43, 255, 255, 0.1) 50%, 
      rgba(43, 255, 255, 0.16) 100%
    ) border-box;
    -webkit-mask: 
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

// Gradient/Stroke 2
.Menu-item-Borders {
  border: 2px solid;
  @include GradientStroke2;
}

// Gradient/Line 1
.Sidebar-Borders {
  border: 2px solid;
  
  // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%),
  // linear-gradient(0deg, rgba(100, 255, 218, 0.16), rgba(100, 255, 218, 0.16));
  
  // border-image-source: url('/assets/img/Styles/GradientStroke 2.svg'); /* 使用 SVG 作為邊框圖像 */
  // border-image-source: url( $imagePath + 'GradientStroke 2.svg');
  border-image-source: url( map-get($imgs, 'Gradient/Line 1') );
  border-image-slice: 1;
}

//------------------------------------------------------------------------------
.table_container {
  display: flex;
  justify-content: center;
  border-radius: 4px;

  border:2px solid var(--green-alpha-alpha-16);
  background-color:  var(--black-black);
  
  .loading_circle {
    padding: 5px 0;
  }
}

.table_container_purple {
  @extend .table_container;
  border:2px solid var(--logo-colors-logo-purple-16);
}

.input-rounded {
  border-radius: 4px;
  border: 2px solid var(--green-alpha-alpha-16);
  padding-left: 20px;

  &::before,
  &::after {
    border-bottom: none !important;
  }
  &:hover::before {
    border-bottom: none !important;
  }

  &.Mui-focused {
    border-color: var(--green-alpha-alpha-40);
    outline: none;
  }
}