@import '/src/assets/scss/pseudo';
@import '/src/assets/scss/variablesV2';

.frame_black {
  // background: var(--logo-colors-logo-purple);
  background: var(--gradient-stroke-2);
}

.step_title {
  // color: var(--logo-colors-logo-purple) !important;
}
