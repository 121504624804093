@import '/src/assets/scss/commonV2.scss';

.gradient_card {
  // background: #f00;
  box-sizing: content-box;
  
  width: 200px;
  height: 240px;
  position: relative;
  cursor: pointer;
  filter: brightness(0.85); /* 暗 */
  transition: all 0.3s ease;
  
  // Gradient/Stroke 2
  @extend .Border-Gradient-Stroke1;
  
  &:hover {
    filter: brightness(1); /* 亮 */
    
    box-shadow: 
      40px 80px 120px 0px rgba(100, 255, 218, 0.3),
      0px 4px 32px 0px rgba(100, 255, 218, 0.6);
    // box-shadow: 
    //   40px 80px 120px 0px rgba(100, 255, 218, 0.3),
    //   0px 4px 32px 0px rgba(100, 255, 218, 0.6),
    //   inset 40px 80px 120px 0px rgba(100, 255, 218, 0.3);
      
    border: 4px solid;
    // Gradient/Stroke 2
    @include GradientStroke2;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%),
    // linear-gradient(0deg, rgba(100, 255, 218, 0.16), rgba(100, 255, 218, 0.16));

    .border_path,
    .border_path_overlay {
      stroke: rgba(100, 255, 218, 0.4);
      stroke-width: 4px;
    }
  }

  // &:active {
  //   .border_path {
  //     stroke: url(#paint1_linear_4006_2821);
  //     stroke-width: 4px;
  //   }
    
  //   .border_path_overlay {
  //     stroke: #64FFDA;
  //     stroke-opacity: 0.16;
  //     stroke-width: 4px;
  //   }
  // }
}

.card_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.border_path,
.border_path_overlay {
  transition: all 0.3s ease;
}

.card_content {
  // box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}