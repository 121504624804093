@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.loading_block {
  // background: #ff0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
