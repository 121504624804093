@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/variablesV2';

$layout-gap: 24px;

//figma 12 Peptide Properties
//---------------------------------------------------------------------------
$frame-left-width: 0px;

//---------------------------------------------------------------------------
$frame-right-width: 1200px - $frame-left-width - $layout-gap;

// 03-table
.frame_right {
  // background: #f00;

  display: flex;
  width: $frame-right-width;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-bottom: 20px;

  // 02-table-cell-protein
  
  .right_cell {
    // background: #0f0;

    box-sizing: border-box;

    display: flex;
    // height: 60px;
    padding: 12px 0px;
    align-items: center;
    // gap: 24px;
    align-self: stretch;

    gap: $layout-gap;
    min-height: 60px;

    // border-bottom: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    border-bottom: 1px solid var(--green-alpha-alpha-24);
  }
  .right_cell_last {
    // background: #00f;

    box-sizing: border-box;

    display: flex;
    // height: 60px;
    padding: 12px 0px;
    align-items: center;
    // gap: 24px;
    align-self: stretch;

    gap: $layout-gap;
    min-height: 60px;
  }

  $cell-label-width: 282px;

  // Frame 29695
  .cell_label {
    // background: #ff0;

    width: $cell-label-width;
    box-sizing: border-box;

    display: flex;
    // width: 282px;
    padding: 6px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  
  // Frame 29720
  .cell_value {
    // background: #0ff;

    // width: 486px;
    width: $frame-right-width - $layout-gap - $cell-label-width;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    // overflow-x: auto;
    overflow-y: auto;
    max-height: 100vh;
    /*給 Safari 以外的瀏覽器讀取*/
    max-height: calc(var(--vh, 1vh) * 100);
    padding-right: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
    word-break: break-all;
  }
  
  //---------------------------------------------------------------------------
  //figma 11 Protein Properties
  // Frame 1000002378
  .right_cell_code {
    // background: #00f !important;
    
    // width: 894px;
    // height: 285px;
    flex-shrink: 0;
    color: var(--white-white-75);
    
    border-radius: 4px;    
    border: 2px solid var(--green-alpha-alpha-8);
    background: url(map-get($imgs,'Gradient/BG 1 Default')) no-repeat;   
    background-size: 100% auto; 
    margin: 12px 0px;
    padding: 12px;
  }
  
  // Frame 1000002383
  .right_cell_title {
    // background: #f00;
    
    box-sizing: border-box;
    
    display: flex;
    align-items: center;
    align-self: stretch;
    
    justify-content: space-between;
    width: $frame-right-width;
    min-height: 60px;
  }
}
