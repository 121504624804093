// @use '/src/assets/scss/variables' as v;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';
@import '/src/assets/scss/commonV2.scss';

/* Header */
.layout {
  // background-color: #00f !important;
  display: flex;
  flex-direction: column;
}

//---------------------------------------------------------------------------
.frame_all {
  // background-color: #f00 !important;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  
  // width: Fixed (1,440px)px;
  // height: Fixed (80px)px;
  // padding: 0px 20px 0px 20px;
  gap: 0px;
  justify-content: space-between;
  
  padding: 0px 120px 0px 120px;
  // width: 1440px;
  height: 80px;
  
  background: rgba(3, 9, 9, 0.75);
  
  box-shadow: 0px 0px 36px -16px rgba(0, 255, 225, 1) inset;
  backdrop-filter: blur(16px);
}

// Prefix
.frame_left {
  // background-color: #0f0;
  
  display: flex;
  align-items: center;
  // width: Hug (680.19px)px;
  // height: Hug (80px)px;
  gap: 60px;
  
  
  // width: 680.19px;
  height: 80px;
}

// Logo
.logo {
  width: 167.19px;
  height: 50px;
  gap: 0px;

  cursor: pointer;
  -webkit-cursor: pointer;
}

// Menu
.frame_menu {
  // background-color: #00f;
  display: flex;
  flex-direction: row;
  
  // width: Hug (453px)px;
  // height: Hug (80px)px;
  // gap: 0px;
  
  height: 80px;
}

.menu_item {
  // background-color: #f00 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  
  // width: Hug (105px)px;
  // height: Fixed (80px)px;
  width: 115px;
  height: 80px;
  padding: 0px 16px 0px 16px;
  gap: 0px;
  box-sizing: border-box;
  
  &.menu_item_active {  
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Menu-item-Borders;
  }

  &:hover {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    @extend .Menu-item-Borders;
  }
  
  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
    @extend .Menu-item-Borders;
  }

  // On click
  // Navigate to: "10Peptide-1Calculated Physicochemical Properties";
  // Animate: Instant;
  // animation-duration: 0ms;

  // On click
  // Navigate to: "10Peptide-1Calculated Physicochemical Properties";
  // Animate: Smart animate;
  // animation-timing-function: ease-out;
  // animation-duration: 300ms;
}

//---------------------------------------------------------------------------
// Suffix
.frame_right {
  // background-color: #00f;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  
  // width: Hug (167px)px;
  // height: Hug (45px)px;
  gap: 0px;
  
  
  // width: 167px;
  height: 45px;
}

.line_6 {
  // width: 22px;
  // height: 0px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  
  // angle: -90 deg;
  width: 0px;
  height: 22px;
  
  border: 1px solid rgba(100, 255, 218, 0.3);
}

.right_user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 16px;  
}

.button_signup {
  // background-color: #00f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  // width: Hug (77px)px;
  // height: Hug (45px)px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
  
  width: 105px;
  height: 80px;
  box-sizing: border-box;
  
  &:hover {
    // cursor: pointer;
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%);
    
    // border: 1px solid;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%),
    // linear-gradient(0deg, rgba(100, 255, 218, 0.16), rgba(100, 255, 218, 0.16));
    @extend .Menu-item-Borders;
  }
  &:active {
    background: linear-gradient(180deg, rgba(43, 255, 255, 0.32) 0%, rgba(43, 255, 255, 0.1) 50%, rgba(43, 255, 255, 0.16) 100%);
    
    @extend .Menu-item-Borders;
  }
}

//---------------------------------------------------------------------------
// Top Search bar
.frame_search {
  // background-color: burlywood;
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center; 
  justify-content: center;

  left: 120px + 167 + 60; //padding-left + Logo width + gap
  height: 80px;
  z-index: 1;
}

//------------------------------------------------------------------------------
// Light
.frame_light {
  // background: #ff0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  .light {
    // background: #f00 !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    justify-items: center;
    width: 900px;
  }

  .light_line4 {
    position: absolute;
    
    width: 351.72px;
    // height: 0px;
    left: 274.14px;
    gap: 0px;
    // border: 2px 0px 0px 0px;
    
    top: 0px;
    
    // Gradient/Line 1
    // border: 2px 0px 0px 0px solid;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%);
    @extend .Sidebar-Borders;
  }

  .light_line3 {
    position: absolute;
    
    width: 900px;
    height: 0px;
    gap: 0px;
    // border: 2px 0px 0px 0px;
    
    top: 0px;
    
    // Gradient/Line 1
    // border: 2px 0px 0px 0px solid;
    // border-image-source: linear-gradient(90deg, rgba(43, 255, 255, 0) 0%, #2BFFFF 50%, rgba(43, 255, 255, 0) 100%);
    @extend .Sidebar-Borders;
  }
}