@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/commonV2.scss';

// Section
.layout {
  // background-color: #0f0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  // align-items: flex-start;
  box-sizing: border-box;
  
  // width: Fill (1,440px)px;
  // height: Hug (1,814px)px;
  padding: 80px 0px 80px 0px;
  gap: 60px;
  

  // width: 1440px;
  height: 1814px;
}

//---------------------------------------------------------------------------
// Title
.frame_top {
  // background-color: #00f;
  display: flex;
  flex-direction: column;
  text-align: center;
  
  // width: Fixed (800px)px;
  // height: Hug (94px)px;
  gap: 12px;
  
  
  height: 94px;
}

//---------------------------------------------------------------------------
// Functions
.frame_center {
  // width: Hug (1,440px)px;
  // height: Hug (1,500px)px;
  gap: 0px;
  
}

// Functions lv.2
// Light + Rectangle 210
.center_image {
  // background-color: #f00;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  
  width: 1440px;
  height: 500px;
  gap: 0px;
}

.image_border {
  
  // Light
  border: 2px solid;
  // Gradient/Line 1
  @include GradientLine1;
  
  // Rectangle 210
  // Inner Shadow/Inner Shadow 4
  box-shadow: 0px 0px 36px -16px rgba(0, 255, 225, 1) inset;
  backdrop-filter: blur(16px);
}

//---------------------------------------------------------------------------
// Functions/Contents
.center_text1,
.center_text2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  width: 460px;
  height: 500px;
  gap: 0px;
  
  
  box-shadow: 0px 0px 36px -16px rgba(0, 255, 225, 0.3) inset;
  backdrop-filter: blur(16px);
  
  background: linear-gradient(0deg, rgba(3, 9, 9, 0.8), rgba(3, 9, 9, 0.8)),
  radial-gradient(41.33% 93.34% at 50.2% 7.2%, rgba(100, 255, 218, 0.6) 0%, rgba(10, 10, 10, 0) 100%);

  @extend .Border-Gradient-Stroke1;
}
.center_text1 {
  // background: #0f0 !important;
  position: absolute;
  left: 100px + 20px;
}
.center_text2 {
  // background: #0f0 !important;
  position: absolute;
  left: 880px - 20px;
}

// Text
.text_frame {
    display: flex;
    flex-direction: column;
    // width: Fixed (300px)px;
    // height: Hug (105px)px;
    // top: 197.5px;
    top: 197.5px;
    left: 380px;
    gap: 16px;
    
    // angle: -180 deg;
    
    width: 300px;
}
